import React from "react";
import { graphql } from "gatsby";

import CallToAction from "../components/CallToAction/CallToAction";
import CasesCarousel from "../components/Carousel/Cases/CasesCarousel";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Layout from "../components/Layout/Layout";
import MarkdownHero from "../components/Hero/MarkdownHero";
import Post from "../components/Post/Post";
import SingleImage from "../components/SingleMedia/SingleImage";


const PracticeAreaTemplate = ({
  data
}) => {
  const {
    markdownRemark: {
      frontmatter: {
        title,
        subtitle,
        description,
        template,
        featuredImage: {
          childImageSharp: {
            fluid
          }
        },
        altText
      },
      html
    }
  } = data;
  
  return (
    <Layout
      title={ title }
      description={ description }
    >
      <Header
        colorScheme="light"
      />

      <MarkdownHero
        title={ title }
        subtitle={ subtitle }
        template={ template }
        contactInfo={ false }
        colorScheme="light"
      />

      <SingleImage
        image={ fluid }
        imageAlignment="left"
        altText={ altText }
        colorScheme="light"
      />

      <Post
        body={ html }
        template={ template }
      />

      <CasesCarousel
        heading="Active Class Actions"
        buttonText="View all class actions"
        pageLink="/class-actions"
      />

      <CallToAction />

      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        subtitle
        description
        template
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        altText
      }
    }
  }
`;

export default PracticeAreaTemplate;